import { graphql } from "gatsby"
import get from "lodash.get"
import React from "react"
import Layout from "../components/Layout"
import Link from "../components/Link"
import HeroV1 from "../components/slices/hero-v1"
import cn from "classnames"
import GatsbyImage from "gatsby-image"
import GatsbyBackgroundImage from "gatsby-background-image"
import { FaCalendarAlt, FaCommentDots, FaUserAlt } from "react-icons/fa"
import RecentWork from "../components/slices/recent-work"
import UpcomingEvents from "../components/slices/upcoming-events"
export default function EventTemplate({ data }) {
  const blogSlices = get(data, "prismicBlog.data.body")
  const additionalWidgets = get(data, "prismicBlog.data.body1")
  const content = get(data, "prismicPost.data.content")
  const nextGame = get(data, "prismicPost.data.next_game_section.document")
  const game = get(data, "prismicPost.data.game.document.data")

  return (
    <Layout>
      {blogSlices
        ? blogSlices.map((slice, index) => {
            switch (slice.__typename) {
              case "PrismicBlogBodyHero":
                return (
                  <HeroV1
                    parentLink="/events/"
                    key={index}
                    data={slice}
                    blogTitle={get(data, "prismicPost.data.title.text")}
                  />
                )

              default:
                return null
            }
          })
        : null}
      <section className="pull-section">
        <div className="container">
          <div className="game-details-grid">
            <div>
              <ListItem post={get(data, "prismicPost.data")} />
            </div>
            {game && (
              <div className="space-y-8">
                <div className="bg-primary-dark p-8 grid grid-cols-2 gap-4 text-sm max-w-lg">
                  <div>Type</div>
                  <div>{game.type}</div>
                  <div>Rating</div>
                  <div className="w-8 h-8 bg-primary rounded-full leading-none  flex-center">
                    {game.rating}
                  </div>
                  <div>Developed by</div>
                  <div>{game.company}</div>
                  <div>Ganre (s):</div>
                  <div>
                    {game.genres &&
                      game.genres.map(({ genre }) => genre).join(", ")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {content && (
        <section className=" ">
          <div className="container space-y-12">
            {content.map((item, index) => {
              const text = get(item, "text.html", "")
              const image = get(item, "image.fluid")
              return (
                <div key={index} className="row ">
                  <div
                    className={cn("col w-full  md:flex items-center", {
                      "md:w-1-2 lg:w-2-3 prose prose-sm p-opacity-80": image,
                    })}
                  >
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                    ></div>
                  </div>
                  {image && (
                    <div className="col w-full md:w-1-2 lg:w-1-3 phone:order-first">
                      <GatsbyImage
                        fluid={image}
                        className="h-full"
                        imgStyle={{ height: "100%" }}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </section>
      )}
      {additionalWidgets &&
        additionalWidgets.map((widget) => {
          switch (widget.__typename) {
            case "PrismicBlogBody1UpcomingEvents":
              return <UpcomingEvents data={widget}></UpcomingEvents>

            default:
              break
          }
        })}
      {nextGame && (
        <GatsbyBackgroundImage
          fluid={[
            get(nextGame, "data.background_image.fluid") ||
              "linear-gradient(transparent,transparent)",
          ]}
        >
          <section className="section-overlay">
            <div className="  min-h-1000 container flex items-center py-40 ">
              <div className="row w-full">
                <div className="col  hidden md:flex  lg:w-1-2 md:w-1-4"></div>
                <div
                  className={cn(
                    "col w-full tablet:order-first  lg:w-1-2 md:w-3-4  flex-grow md:max-w-xl md:px-20"
                  )}
                >
                  <div
                    className=" prose space-y-5 p-opacity-80"
                    dangerouslySetInnerHTML={{
                      __html:
                        get(nextGame, "data.title.html", "") +
                        get(nextGame, "data.excerpt.html", ""),
                    }}
                  ></div>
                  <Link to={"/"} className="button mt-10">
                    Buy Now
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </GatsbyBackgroundImage>
      )}

      {additionalWidgets &&
        additionalWidgets.map((widget) => {
          switch (widget.__typename) {
            case "PrismicBlogBody1RecentWork":
              return <RecentWork data={widget}></RecentWork>

            default:
              break
          }
        })}
    </Layout>
  )
}
export const query = graphql`
  query($uid: String) {
    prismicBlog {
      uid
      data {
        body {
          ... on PrismicBlogBodyHero {
            id
            primary {
              background_image {
                fluid(imgixParams: { q: 90 }) {
                  ...GatsbyPrismicImageFluid
                }
              }
              title {
                html
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicBlogBody1RecentWork {
            id
            primary {
              title {
                text
              }
            }
            items {
              item {
                uid
                document {
                  ... on PrismicPost {
                    data {
                      thumbnail: featured_image {
                        fluid(imgixParams: { q: 90 }) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      date(formatString: "DD/MM/YYYY")
                      name: title {
                        text
                      }
                      excerpt {
                        html
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicBlogBody1UpcomingEvents {
            id
            primary {
              title {
                text
              }
            }
            items {
              item {
                document {
                  ... on PrismicPost {
                    data {
                      thumbnail: featured_image {
                        fluid(imgixParams: { q: 90 }) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      date(formatString: "DD/MM/YYYY")
                      name: title {
                        text
                      }

                      excerpt {
                        html
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicPost(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        excerpt {
          html
        }
        featured_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        game {
          document {
            ... on PrismicGame {
              data {
                type
                company
                genres {
                  genre
                }
                rating
              }
            }
          }
        }
        date(formatString: "MMMM, DD, YYYY")
        author
        show_new_games_joined
        content {
          image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
          text {
            html
          }
        }
        display_comments
        next_game_section {
          document {
            ... on PrismicGame {
              id
              data {
                background_image {
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
                excerpt {
                  html
                }
                title {
                  html
                }
              }
              uid
            }
          }
        }
      }
    }
  }
`

function ListItem({ post }) {
  const image = get(post, "featured_image.fluid")
  return (
    <>
      <div className=" ">
        <div className="row lg:flex-no-wrap tablet:space-y-6">
          <div className="col w-full lg:w-auto  flex-shrink-0">
            {image && (
              <div className="featured-image-width  ">
                <GatsbyImage
                  className="h-full"
                  imgStyle={{ marginTop: 0 }}
                  fluid={image}
                />
              </div>
            )}
          </div>
          <div className="col  w-full lg:w-auto">
            <div>
              <span className="bg-primary px-6 py-2 mb-2 inline-block text-sm leading-none">
                Event
              </span>
            </div>

            <h1 className="font-semibold mb-4 mt-2  text-2xl">
              {get(post, "title.text")}
            </h1>
            <div
              className="opacity-75 mb-4 text-sm leading-loose"
              dangerouslySetInnerHTML={{
                __html: get(post, "excerpt.html"),
              }}
            ></div>

            <div className="flex flex-wrap items-center opacity-50 text-sm">
              {/*<div>
                <FaCommentDots className="inline-block mr-1" />
              </div>
              <div className="ml-10 mr-auto">*/}
              <div className="mr-auto">
                <FaUserAlt className="inline-block mr-1" /> By{" "}
                {get(post, "author")}
              </div>
              <div>
                <FaCalendarAlt className="inline-block mr-1" />{" "}
                {get(post, "date")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
